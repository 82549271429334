import Actions from "modules/actions/";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      actions: new Actions(),
      isLoading: false,
      isEditing: false,
      account: {
        id: null,
        firstName: "",
        lastName: "",
      },
      accountCopy: {
        id: null,
        firstName: "",
        lastName: "",
      },
      rules: {
        required: (val) =>
          (val !== null && val.length !== 0) || "This field is required.",
      },
    };
  },
  methods: {
    editUserInfoState() {
      this.isEditing = true;
    },
    cancelEditUserInfoState() {
      this.accountCopy = JSON.parse(JSON.stringify(this.account));

      this.isEditing = false;
    },
    async saveUserInfo() {
      try {
        const validated = await this.$refs.userInfoForm.validateAsync();
        if (!validated) return;

        await this.actions.updateAccountDetails({
          accountId: {
            type: "UUID!",
            value: this.accountCopy.id,
            unincludeToFields: true,
          },
          queries: {
            updateFirstName: {
              firstName: {
                type: "String!",
                value: this.accountCopy.firstName,
              },
            },
            updateLastName: {
              lastName: {
                type: "String!",
                value: this.accountCopy.lastName,
              },
            },
            updateName: {
              name: {
                type: "String!",
                value: `${this.accountCopy.firstName} ${this.accountCopy.lastName}`,
              },
            },
          },
        });

        this.account = JSON.parse(JSON.stringify(this.accountCopy));
        this.isEditing = false;

        // save user store state
        const user = await this.actions.getUser();
        user.name = `${this.account.firstName} ${this.account.lastName}`;
        user.$save();

        this.showNotifyMessage({
          message: `User information saved.`,
          type: "success",
        });
      } catch (err) {
        this.showNotifyMessage({
          message: `Problem has occurred while saving data.`,
          type: "danger",
        });
      }
    },
  },
  async created() {
    try {
      const user = await this.actions.getUser();

      if (user.email.length > 0) {
        const result = await this.actions.getAccountByEmail(
          {
            email: {
              type: "String",
              value: user.email,
            },
          },
          ["firstName", "lastName"]
        );

        if (result) {
          this.account.id = result.id;
          this.account.firstName = result.firstName;
          this.account.lastName = result.lastName;

          this.accountCopy = JSON.parse(JSON.stringify(this.account));
        }
      }
    } catch (err) {
      this.showNotifyMessage({
        message: "Unable to fetch data.",
        type: "danger",
      });
    }
  },
};
